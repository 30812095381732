<template>
  <b-modal
    id="delete-scheduled-message"
    centered
    title="Remove Scheduled Messages"
    hide-footer
    ref="delete-scheduled-message-ref"
  >
    <b-card-text> Are you sure you want to remove this batch of scheduled messages from ever being sent ? </b-card-text>

    <b-form-group
      label-for="reason"
      label="Reason for removal of this scheduled messages"
    >
      <b-form-input
        id="reason"
        v-model="reason"
        name="reason"
      />
      <small class="text-danger" v-if="reason == '' || reason == 0">This field is required</small>
    </b-form-group>

    <hr />
    
    <div class="d-flex justify-content-end align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="ml-2"
        :disabled="isLoading || !reason"
        @click="deleteScheduledMessage"
      >
        <b-spinner small v-if="isLoading" />
        <span v-if="isLoading">&nbsp;</span>
        Remove
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BCardText, BSpinner, BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCardText,
    BSpinner,
    BButton,
    BFormGroup,
    BFormInput,
  },
  props: ["id"],
  emits: ["scheduled-refresh"],
  data(){
    return {
      isLoading: false,
      reason: null,
    };
  },
  methods:{
    deleteScheduledMessage(){
      this.isLoading = true;
      this.$http
        .post("/scheduled-messages/remove/"+ this.id, {
          reason: this.reason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('scheduled-refresh');
          this.isLoading = false;
          this.hideModal();
          this.reason = null;
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
        });
    },
    hideModal() {
      this.$refs['delete-scheduled-message-ref'].hide();
    }
  }
};
</script>
